import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const UPDATE_USER = 'updateUser';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_ERROR = 'setError';
export const SET_ISAUTHENTICATED = 'setIsAuthenticated';
export const SET_SUPERADMIN = 'setSuperAdmin';
export const SET_MARKETING = 'setMarketing';

const state = {
  errors: null,
  user: {},
  isAuthenticated: true,
  isSuperAdmin: '',
  isMarketing: false
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getSuperAdmin(state) {
    return state.isSuperAdmin;
  },
  getMarketing(state) {
    return state.isMarketing;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post('login', credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('users', { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context, account_id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get('verify', account_id || '')
        .then((data) => {
          if (data) {
            let newData = { ...data.data, token: JwtService.getToken() };
            let dataSet = {
              data: { ...newData }
            };
            context.commit(SET_AUTH, dataSet);
          } else {
            context.commit(PURGE_AUTH);
            this.$router.push('login');
          }
        })
        .catch((err) => {
          if (err) {
            context.commit(PURGE_AUTH);
            this.$router.push('login');
          }
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put('user', user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = !!JwtService.getToken();
    state.user = user;
    state.errors = {};
    JwtService.saveToken(user.data.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_ISAUTHENTICATED](state) {
    state.isAuthenticated = !!JwtService.getToken();
  },
  [SET_SUPERADMIN](state, value) {
    state.isSuperAdmin = value;
  },
  [SET_MARKETING](state, value) {
    state.isMarketing = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
