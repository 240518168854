export const excludes = {
  admin: ['Deposit Manual', 'Role', 'Reward List', 'Coupon List', 'Account', 'Configs'],
  accountant: [
    'Contents',
    'Configs',
    'User List',
    'Role List',
    // 'Customer List',
    'Statistics',
    // 'Admin',
    'Reward Rule',
    'Order List',
    'Ticket List',
    'Coupons',
    'Notifications',
    'Reward',
    'Account',
    'Ticket',
    'Deposit Transactions',
    'Notification Listener',
    'Notify Listener',
    'Dashboard',
    'Orders',
    'Referrals',
    'Deposit Manual',
    'Big Prize Tickets',
    'Buy Group',
    'VNPay Transaction'
  ],
  marketing: [
    'Contents',
    'Configs',
    'User List',
    'Role List',
    'Customer List',
    'Ticket Statistics',
    'Transactions',
    'Reward Rule',
    'Orders',
    'Reward Statistics',
    'Coupons',
    'Notifications',
    'Reward',
    'Account',
    'Ticket',
    'Notification Listener',
    'Notify Listener',
    'Admin',
    'Operator Statistics',
    'Referrals',
    'Buy Group',
    'VNPay Transaction'
  ],
  athena: [
    'Contents',
    'Configs',
    'User List',
    'Role List',
    'Customer List',
    'Ticket Statistics',
    'Transactions',
    'Reward Rule',
    'Orders',
    'Reward Statistics',
    'Coupons',
    'Notifications',
    'Reward',
    'Account',
    'Ticket',
    'Admin',
    'Notification Listener',
    'Notify Listener',
    'Operator Statistics',
    'Referrals',
    'Buy Group',
    'VNPay Transaction'
  ],
  super_admin: [],
  stakeholders: [
    'Contents',
    'Configs',
    'Transactions',
    'Orders',
    'Notifications',
    'Admin',
    'Notification Listener',
    'Ticket Statistics',
    'Reward Statistics',
    'Marketing Statistics',
    'Operator Statistics',
    'Referrals',
    'Buy Group',
    'VNPay Transaction'
  ],
  vipmanager: [
    'Contents',
    'Configs',
    'Statistics',
    'Transactions',
    'Orders',
    'Notifications',
    'Admin',
    'Notification Listener',
    'Invite Friends (INCOMING)',
    'Campaigns (INCOMING)',
    'Shop Accounts (INCOMING)',
    'Buy Group',
    'VNPay Transaction'
  ]
};

export const ticketStatus = ['big', 'small', 'odd', 'even'];
